import React from 'react';
import { ThemeProvider } from '../contexts/theme';
import { t } from '../../js/common/translations';
import { useBreakpoint } from '../hooks/useBreakpoint';
import {
  SectionHero,
  Hero,
  HeroTitle,
  CelebrateSection,
  CelebrateSectionRight,
  ApartmentsSection,
  ApartmentsSectionTextWrapper,
  DestinationSection,
  DestinationSectionDescription,
  DestinationSectionPicturesWrapper,
  DestinationSectionPictures,
  Section,
  SectionTitle,
  SectionSubtitle,
  PerfectStaySectionLeft,
  PerfectStaySectionLeftPicture
} from '../pages/NewYear.styles';
import { PropertyCardSlider, CTABottomBar } from '../UI/modules';
import { Box, Button, Container, HtmlTranslation, SvgIcon, Typography } from '../UI/elements';
import Hero1920Jpg from '../../images/new_year/header_1920x1080_2.jpg';
import Hero1280Jpg from '../../images/new_year/header_1280x840_2.jpg';
import Hero5Apartments from '../../images/new_year/hero_5apartments.png';
import Hero3Apartments from '../../images/new_year/hero_3apartments.png';
import Hero1Apartments from '../../images/new_year/hero_1apartments.png';
import DestinationImg1 from '../../images/new_year/destination_1.jpg';
import DestinationImg2 from '../../images/new_year/destination_2.jpg';
import ThirdSectionImg from '../../images/new_year/third_section.jpg';

const CasatbLanding = ({
  fiveSeasonalProperties = [],
  firstFiveProperties = [],
  apartmentsRioPath
}) => {
  const currentBreakpoint = useBreakpoint();
  const isMobile = ['xs', 'sm'].includes(currentBreakpoint);
  const extraSmallScreen = ['xs'].includes(currentBreakpoint);
  const mediumScreen = ['md'].includes(currentBreakpoint);
  const largeScreen = ['lg', 'xl'].includes(currentBreakpoint);

  const highlightedProperties = (fiveSeasonalProperties.length > 0) ? (
    fiveSeasonalProperties
  ) : (
    firstFiveProperties
  );

  return (
    <ThemeProvider>
      <SectionHero sx={{ height: '100vh' }}>
        <Hero component="picture">
          {largeScreen ? (
            <Hero component="img" src={Hero1920Jpg} />
          ) : mediumScreen ? (
            <Hero component="img" src={Hero1920Jpg} />
          ) : (
            <Hero component="img" src={Hero1280Jpg} />
          )}
        </Hero>
        <Box position="absolute" zIndex={1} sx={{ textAlign: 'center', bottom: '0' }} mb={6}>
          <HeroTitle fontFamily="Cambon">
            <HtmlTranslation text={t('landing.title_v2_1')} />
            <Box component="span" sx={{ whiteSpace: 'nowrap' }}>{t('landing.title_v2_2')}</Box>
          </HeroTitle>
          {largeScreen ? (
            <Box component="img" pt={3} px={1} src={Hero5Apartments} maxHeight="10rem" />
          ) : mediumScreen ? (
            <Box component="img" pt={3} px={1} src={Hero3Apartments} maxHeight="12rem" />
          ) : (
            <Box component="img" pt={3} px={1} src={Hero1Apartments} maxHeight="14rem"/>
          )}
        </Box>
      </SectionHero>
      <Container disableGutters sx={{ paddingY: { xs: 2, md: 3.5 }, paddingX: { xs: 1, sm: 2 } }}>
        <Section>
          <SectionTitle fontFamily="Cambon">
            {t('landing.sections.celebrate.title')}
          </SectionTitle>
          <SectionSubtitle>
            {t('landing.sections.celebrate.subtitle')}
          </SectionSubtitle>
        </Section>
        <CelebrateSection alignItems="flex-start">
          <PerfectStaySectionLeft mr={1.5}>
            <PerfectStaySectionLeftPicture component="picture" display={{ xs: 'none', md: 'flex' }}>
              <PerfectStaySectionLeftPicture component="img" sx={{ objectFit: 'cover' }} src={ThirdSectionImg} />
            </PerfectStaySectionLeftPicture>
          </PerfectStaySectionLeft>
          <CelebrateSectionRight>
            <Typography fontSize={18}>
              <SvgIcon name="brush_alt" size={24} mr={0.5} />
              {t('landing.sections.celebrate.content_1')}
            </Typography>
            <Typography fontSize={18}>
              <SvgIcon name="crockery" size={24} mr={0.5} />
              {t('landing.sections.celebrate.content_2')}
            </Typography>
            <Typography fontSize={18}>
              <SvgIcon name="comment_heart" size={24} mr={0.5} />
              {t('landing.sections.celebrate.content_3')}
            </Typography>
            <Button
              size="large"
              target="_blank"
              href={`/whatsapp_redirect?website_url=${location.href}&button_type=${t('campaign_tracker.button_type.sales')} ${t('landing.whatsapp_message')}`}
              sx={{ ...(!isMobile && { maxWidth: 220 }) }}
            >
              <SvgIcon name="whatsapp_light" size={20} mr={0.25} />
              {t('landing.whatsapp_us')}
            </Button>
            <Button
              size="large"
              color="secondary"
              target="_blank"
              border={true}
              href={`${apartmentsRioPath}?search[check_in]=2023-12-28&search[check_out]=2024-01-02`}
              sx={{ ...(!isMobile && { maxWidth: 220 }) }}
            >
              {t('landing.button')}
            </Button>
          </CelebrateSectionRight>
          <Box width="100%" display={{ xs: 'flex', md: 'none' }} justifyContent="center">
            <PerfectStaySectionLeftPicture component="picture" >
              <PerfectStaySectionLeftPicture component="img" sx={{ objectFit: 'cover' }} src={ThirdSectionImg} />
            </PerfectStaySectionLeftPicture>
          </Box>
        </CelebrateSection>
      </Container>
      <ApartmentsSection py={{ xs: 1, md: 3.5 }}>
        <Container sx={{ px: { xs: 1, md: 2 } }} maxWidth="xl">
          <ApartmentsSectionTextWrapper mb={2} display="flex" flexDirection="column" alignItems="center">
            <SectionTitle>
              {t('landing.sections.apartments.title')}
            </SectionTitle>
            <SectionSubtitle>
              {t('landing.sections.apartments.subtitle')}
            </SectionSubtitle>
          </ApartmentsSectionTextWrapper>
          <PropertyCardSlider properties={highlightedProperties} withBody={false} seasonalLink={true} season="new_year" />
        </Container>
      </ApartmentsSection>
      <Container disableGutters sx={{ paddingY: { xs: 1, md: 3.5 }, paddingX: { xs: 1, sm: 2 } }}>
        <DestinationSection>
          <SectionTitle>
            {t('landing.sections.best_destination.title')}
          </SectionTitle>
          <DestinationSectionDescription component="div">
            <Box mb={1}>
              {t('landing.sections.best_destination.content_1')}
            </Box>
            <Box mb={1}>
              {t('landing.sections.best_destination.content_2')}
            </Box>
            {t('landing.sections.best_destination.content_3')}
          </DestinationSectionDescription>
          <DestinationSectionPicturesWrapper>
            <DestinationSectionPictures component="picture" left={0} top={{ xs: 0, md: 20 }} position={{ xs: 'relative', md: 'absolute' }} zIndex={10} my={{ xs: 1, md: 0 }}>
              <DestinationSectionPictures component="img" src={DestinationImg1} sx={{ objectFit: 'cover' }} />
            </DestinationSectionPictures>
            <DestinationSectionPictures component="picture" right={0} top={{ xs: 0, md: 125 }} position={{ xs: 'relative', md: 'absolute' }} zIndex={4} my={{ xs: 1, md: 0 }}>
              <DestinationSectionPictures component="img" src={DestinationImg2} sx={{ objectFit: 'cover' }} />
            </DestinationSectionPictures>
            <Box height={{ xs: 0, md: 500 }} />
          </DestinationSectionPicturesWrapper>
        </DestinationSection>
      </Container>
      <CTABottomBar
        actionButtonProps={{
          children: (
            <>
              <SvgIcon name={extraSmallScreen ? 'whatsapp_light' : 'whatsapp'} size={20} mr={0.25} />
              {t('landing.whatsapp_us')}
            </>
          ),
          href: `/whatsapp_redirect?website_url=${location.href}&button_type=${t('campaign_tracker.button_type.sales')} ${t('landing.whatsapp_message')}`,
          target: '_blank',
          className: 'whatsapp-link'
        }}
        text={t('landing.whatsapp_text')}
      />
    </ThemeProvider>
  );
};

export default CasatbLanding;
