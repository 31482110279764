import React from 'react';
import DiamondImage from './DiamondImage';
import { checkWebp } from '../../../../utils/checkWebp';

import HeroImageTwoWebp from '../../../../../images/tenant/hero_2.webp';
import HeroImageTwoJpg from '../../../../../images/tenant/hero_2.jpg';

const DiamondHeroImageTwo = () => {
  const isWebp = checkWebp();

  return (
    <DiamondImage
      width={{ xs: 80, sm: 180, lg: 195 }}
      height={{ xs: 80, sm: 180, lg: 195 }}
      sx={{
        width: { xs: 90, sm: 230, lg: 240 },
        height: { xs: 90, sm: 230, lg: 240 }
      }}
      src={isWebp ? HeroImageTwoWebp : HeroImageTwoJpg}
    />
  );
};

export default DiamondHeroImageTwo;
