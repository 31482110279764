import React from 'react';
import DiamondImage from './DiamondImage';
import { checkWebp } from '../../../../utils/checkWebp';

import HeroImageFourWebp from '../../../../../images/tenant/hero_4.webp';
import HeroImageFourJpg from '../../../../../images/tenant/hero_4.jpg';

const DiamondHeroImageFour = () => {
  const isWebp = checkWebp();

  return (
    <DiamondImage
      width={{ xs: 170, sm: 200, lg: 385 }}
      height={{ xs: 170, sm: 200, lg: 385 }}
      sx={{
        width: { xs: 265, sm: 260, lg: 500 },
        height: { xs: 265, sm: 260, lg: 500 },
        objectPosition: { xs: '0', lg: '-15px 40px' },
        objectFit: 'contain'
      }}
      src={isWebp ? HeroImageFourWebp : HeroImageFourJpg}
    />
  );
};

export default DiamondHeroImageFour;
