import React, { useState } from 'react';
import { checkWebp } from '../../../../utils/checkWebp';
import { t } from '../../../../../js/common/translations';
import { SvgIcon } from '../../../elements';
import RentApartmentSlide from './RentApartmentSlide';
import { SwiperContainer, ControlLeft, ControlRight, RentApartmentSwiper } from './styles';

import StepOneWebp from '../../../../../images/tenant/howToRentApartment/step_1.webp';
import StepOneJpg from '../../../../../images/tenant/howToRentApartment/step_1.jpg';
import StepTwoWebp from '../../../../../images/tenant/howToRentApartment/step_2.webp';
import StepTwoJpg from '../../../../../images/tenant/howToRentApartment/step_2.jpg';
import StepThreeWebp from '../../../../../images/tenant/howToRentApartment/step_3.webp';
import StepThreeJpg from '../../../../../images/tenant/howToRentApartment/step_3.jpg';
import StepFourWebp from '../../../../../images/tenant/howToRentApartment/step_4.webp';
import StepFourJpg from '../../../../../images/tenant/howToRentApartment/step_4.jpg';

const HowToRentApartment = () => {
  const [swiper, setSwiper] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const isWebp = checkWebp();

  const slides = [
    {
      title: t('tenant_page.rent_apartment.step_one.title'),
      description: t('tenant_page.rent_apartment.step_one.description_html'),
      image: isWebp ? StepOneWebp : StepOneJpg
    },
    {
      title: t('tenant_page.rent_apartment.step_two.title'),
      description: t('tenant_page.rent_apartment.step_two.description_html'),
      image: isWebp ? StepTwoWebp : StepTwoJpg
    },
    {
      title: t('tenant_page.rent_apartment.step_three.title'),
      description: t('tenant_page.rent_apartment.step_three.description_html'),
      image: isWebp ? StepThreeWebp : StepThreeJpg
    },
    {
      title: t('tenant_page.rent_apartment.step_four.title'),
      description: t('tenant_page.rent_apartment.step_four.description_html'),
      image: isWebp ? StepFourWebp : StepFourJpg
    },
    {
      title: t('tenant_page.rent_apartment.step_five.title'),
      description: t('tenant_page.rent_apartment.step_five.description_html'),
      image: isWebp ? StepFourWebp : StepFourJpg
    }
  ];

  const SliderControls = () => {
    return (
      <>
        <ControlLeft
          disabled={activeIndex === 0}
          onClick={() => swiper.slidePrev()}
        >
          <SvgIcon name="arrow_left_black" size="16" />
        </ControlLeft>
        <ControlRight
          disabled={activeIndex === slides.length - 1}
          onClick={() => swiper.slideNext()}
        >
          <SvgIcon name="arrow_right_black" size="16" />
        </ControlRight>
      </>
    );
  };

  return (
    <SwiperContainer sx={{ px: [0, 0, 4] }}>
      <SliderControls />
      <RentApartmentSwiper
        loop={false}
        slides={slides.map(({ description, image, title }, index) => (
          <RentApartmentSlide key={index} description={description} image={image} title={title} />
        ))}
        onSwiper={setSwiper}
        onSlideChange={({ activeIndex }) => setActiveIndex(activeIndex)}
      />
    </SwiperContainer>
  );
};

export default HowToRentApartment;
