import React, { useEffect, useRef } from 'react';
import { ThemeProvider } from '../../contexts/theme';
import {
  SvgIcon
} from '../elements';
import { t } from '../../../js/common/translations';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { useScroll } from '../../hooks/useScroll';

import { ButtonBarItem, BarContainer } from './FilterButtonsBar.styles';
import FilterSort from './SearchPage/FilterSort';
import { useToggle } from '../../hooks/useToggle';

const FilterButtonsBar = ({ checkIn, checkOut, neighbourhoodValue, filterModalActive, searchAttributes }) => {
  const scrollState = useScroll();
  const currBreakpoint = useBreakpoint();
  const isMobile = ['xs', 'sm'].includes(currBreakpoint);
  const hiddenByScroll = scrollState.direction === 'down' && scrollState.value > 80;
  const [openSortFilter, setOpenSortFilter] = useToggle();
  const isWebview = window.isWebview;
  const filterBarRef = useRef(null);

  if (isWebview) {
    useEffect(() => {
      const sendCloseMessage = () => window.ReactNativeWebView.postMessage('FilterButtonsBarClicked');

      filterBarRef.current.addEventListener('click', sendCloseMessage);

      return () => {
        filterBarRef.current.removeEventListener('click', sendCloseMessage);
      };
    }, []);
  }

  const FormattedDatesBtnText = () => (
    <>
      {checkIn}
      {checkIn && checkOut && (
        <SvgIcon name="arrow_dates_right" size="12" style={{ margin: '0 8px' }} />
      )}
      {checkOut}
      {!checkIn && !checkOut && t('search_page.toolbar.filters.dates_label')}
    </>
  );

  const FormattedDatesAreasText = () => {
    return neighbourhoodValue?.length
      ? t(`search_page.toolbar.filters.${neighbourhoodValue?.length > 1 ? 'neighbourhoods_value' : 'neighbourhood_value'}`)
      : t('search_page.toolbar.filters.neighbourhoods_label');
  };

  return (
    <ThemeProvider>
      <BarContainer
        retracted={isMobile && hiddenByScroll}
        retractionPercentage="200"
        ref={filterBarRef}
      >
        <ButtonBarItem
          startIcon={{ name: (checkIn || checkOut) ? 'calendar_filters_white' : 'calendar_filters_black', size: '15' }}
          variant={(checkIn || checkOut) ? 'contained' : 'outlined'}
          className='dates-button search-select-dates reservation-sticky-submit'
        >
          <FormattedDatesBtnText />
        </ButtonBarItem>
        <ButtonBarItem
          startIcon={{ name: neighbourhoodValue ? 'location_filters_white' : 'location_filters_black', size: '15' }}
          variant={neighbourhoodValue ? 'contained' : 'outlined'}
          data-bs-toggle='modal'
          data-bs-target='#modalAreas'
          id="areas_button"
        >
          <FormattedDatesAreasText />
        </ButtonBarItem>
        <ButtonBarItem
          startIcon={{ name: filterModalActive ? 'slider_filters_white' : 'slider_filters_black', size: '15' }}
          sx={{ m: 0.25 }}
          variant={filterModalActive ? 'contained' : 'outlined'}
          data-bs-toggle='modal'
          data-bs-target='#modalFilters'
          id="filters_button"
        >
          {t('search_page.toolbar.filters.filter_modal_btn')}
        </ButtonBarItem>
        <ButtonBarItem
          variant='outlined'
          onClick={setOpenSortFilter}
        >
          {t('search_page.toolbar.sort.sort_modal_btn')}
        </ButtonBarItem>
        <ButtonBarItem
          variant='outlined'
          className="show-list-button d-none"
        >
          {t('search_page.toolbar.list')}
        </ButtonBarItem>
        <ButtonBarItem
          variant='outlined'
          className="show-map-button"
        >
          {t('search_page.toolbar.map')}
        </ButtonBarItem>
      </BarContainer>
      <FilterSort
        filterSortModalOpen={openSortFilter}
        handleOpenDialog={setOpenSortFilter}
        searchAttributes={searchAttributes}
      />
    </ThemeProvider>
  );
};

export default FilterButtonsBar;
