import React, { useState, useRef } from 'react';
import {
  Box,
  Typography,
  Button,
  Container,
  Grid,
  ButtonGroup,
  SvgIcon,
  Dialog,
  Drawer,
  HtmlTranslation
} from '../UI/elements';
import { Swiper, CTABottomBar, CorporateForm } from '../UI/modules';
import { ThemeProvider } from '../contexts/theme';
import { useBreakpoint } from '../hooks/useBreakpoint';
import { useIsInViewport } from '../hooks/useIsInViewport';
import { checkWebp } from '../utils/checkWebp';
import { t } from '../../js/common/translations';

import {
  HeroContainer,
  Hero,
  HeroInfoWrapper,
  CorporateCard,
  SliderWrapper,
  Slider,
  IconRounded,
  SectionSliderWrapper,
  ClientSliderTextArea,
  CitiesContainer,
  CityCard,
  CityName,
  heroImages,
  slidesImages
} from './Corporate.styles';

import rioImage from '../../images/corporate/cities/rio-de-janeiro.png';
import spImage from '../../images/corporate/cities/sao-paulo.png';

const Corporate = ({ recaptchaSiteKey, contactFormUrl }) => {
  const heroButton = useRef(null);
  const currentBreakpoint = useBreakpoint();
  const isHeroButtonVisible = useIsInViewport(heroButton);
  const isWebp = checkWebp();
  const [clientSwiper, setClientSwiper] = useState(null);
  const [clientSliderActive, setClientSliderActive] = useState(0);
  const [formOpen, setFormOpen] = useState(false);

  const corporateCardContents = [
    {
      name: 'account_manager',
      icon: 'user_grey'
    },
    {
      name: 'save_money',
      icon: 'dolar_sign_rounded_grey'
    },
    {
      name: 'flexible_booking',
      icon: 'calendar_grey'
    },
    {
      name: 'neighbourhoods',
      icon: 'map_grey'
    },
    {
      name: 'interior_design',
      icon: 'paint_tool_grey'
    },
    {
      name: 'equiped',
      icon: 'sign_contract_grey'
    }
  ];

  const corporateClients = [
    {
      type: 'managers',
      image: slidesImages.first[currentBreakpoint]
    },
    {
      type: 'business',
      image: slidesImages.second[currentBreakpoint]
    },
    {
      type: 'finance',
      image: slidesImages.third[currentBreakpoint]
    }
  ];

  const cities = [
    {
      name: 'São Paulo',
      image: spImage
    },
    {
      name: 'Rio de Janeiro',
      image: rioImage
    }
  ];

  const CorporateCardWithContent = ({ content, mobile }) => (
    <CorporateCard sx={{ textAlign: 'left' }} mobile={mobile}>
      <IconRounded name={content.icon} />
      <Typography variant="h5" mt={2} gutterBottom component="h3">
        {t(`companies_page.benefits.features.${content.name}_title`)}
      </Typography>
      <Typography variant="body2" mt={0.5} gutterBottom component="p">
        {t(`companies_page.benefits.features.${content.name}_desc`)}
      </Typography>
    </CorporateCard>
  );

  const ClientsSliderControls = () => (
    <Box
      sx={{
        position: 'absolute',
        p: 1.5,
        top: 0,
        right: 0,
        zIndex: 999,
        display: ['none', 'block']
      }}
    >
      <ButtonGroup
        buttons={[
          {
            children: <SvgIcon name="control_chevron_left" />,
            disabled: clientSliderActive === 0,
            onClick: () => clientSwiper?.slidePrev()
          },
          {
            children: <SvgIcon name="control_chevron_right" />,
            disabled: clientSliderActive === (corporateClients.length - 1),
            onClick: () => clientSwiper?.slideNext()
          }
        ]}
        buttonProps={{
          variant: 'contained',
          color: 'secondary',
          size: 'large',
          pill: false
        }}
        variant="text"
      />
    </Box>
  );

  return (
    <ThemeProvider>
      <Box mx={1}>
        <Container disableGutters maxWidth="xl" sx={{ py: 1 }}>
          <HeroContainer>
            <Hero component="picture">
              <Hero src={heroImages[isWebp ? 'webp' : 'png'][currentBreakpoint]} component="img" alt={t('corporate_alt.hero')} />
            </Hero>
            <Box
              width="100px"
              height="100px"
              top="100px"
              position="absolute"
              ref={heroButton}
            />
            <HeroInfoWrapper>
              <Box
                alignSelf='flex-start' component="span"
              >
                <Box
                  sx={{
                    backdropFilter: ['blur(4px)', 'none'],
                    background: ['rgba(254, 254, 254, 0.5)', 'none'],
                    borderRadius: '0.75rem',
                    p: { xs: 1, lg: 0 }
                  }}
                >
                  <Typography
                    variant="h1"
                    sx={{
                      color: 'secondary.main',
                      typography: ['h4', 'h1'],
                      fontWeight: [800, 700],
                      '&&': {
                        fontFamily: 'Cambon'
                      }
                    }}
                    gutterBottom
                  >
                    <HtmlTranslation text={t('companies_page.hero.title_html')} />
                  </Typography>
                  <Typography
                    variant="h6"
                    component="span"
                    sx={{
                      display: 'block',
                      color: 'secondary.main',
                      fontWeight: 500
                    }}
                    gutterBottom
                  >
                    {t('companies_page.hero.description')}
                  </Typography>
                  <Button
                    className="request-a-quote-bt-fixed"
                    variant="contained"
                    color="secondary"
                    size="large"
                    sx={{ mt: 0.5 }}
                    onClick={() => setFormOpen(true)}
                  >
                    {t('companies_page.hero.cta')}
                  </Button>
                </Box>
              </Box>
            </HeroInfoWrapper>
          </HeroContainer>
        </Container>
      </Box>
      <Box component="section" sx={{ py: [3, 3, 5] }} className="benefits">
        <Container maxWidth="xl" disableGutters sx={{ px: 1 }}>
          <Box sx={{ mb: 2, textAlign: ['left', 'center'] }}>
            <Typography
              component="span"
              variant="lead"
              color="primary.light"
              sx={{
                fontWeight: 'bold',
                textTransform: 'uppercase'
              }}
            >
              {t('companies_page.benefits.section')}
            </Typography>
            <Typography
              component="h2"
              variant="h1"
              sx={{
                fontFamily: 'Cambon',
                my: 1
              }}
            >
              <HtmlTranslation text={t('companies_page.benefits.title_html')} />
            </Typography>
            <Typography
              component="span"
              variant="h5"
              color="primary.70"
              sx={{ fontWeight: '500' }}
            >
              {t('companies_page.benefits.description')}
            </Typography>
          </Box>
          {currentBreakpoint === 'xs' ? (
            <Swiper
              slides={corporateCardContents.map((content, i) => (
                <Box
                  key={i}
                  sx={{
                    height: 1,
                    width: 1,
                    display: 'flex',
                    justifyContent: 'center'
                  }}>
                  <CorporateCardWithContent key={i} content={content} mobile />
                </Box>
              ))}
              loop={false}
              spaceBetween={0}
            />
          ) : (
            <Grid container spacing={2}>
              {corporateCardContents.map((content, i) => (
                <Grid item xs={12} sm={6} lg={4} key={i}>
                  <CorporateCardWithContent content={content} />
                </Grid>
              ))}
            </Grid>
          )}
        </Container>
      </Box>
      <Box component="section" sx={{ pb: [3, 4.5] }} className="properties">
        <Container maxWidth="xl" disableGutters sx={{ px: 1 }}>
          <Box sx={{ mb: 2, textAlign: ['left', 'center'] }}>
            <Typography
              component="span"
              variant="lead"
              color="primary.light"
              sx={{
                fontWeight: 'bold',
                textTransform: 'uppercase'
              }}
            >
              {t('companies_page.apartments.section')}
            </Typography>
            <Typography
              component="h2"
              variant="h1"
              sx={{
                fontFamily: 'Cambon',
                my: 1
              }}
            >
              {t('companies_page.apartments.title')}
            </Typography>
          </Box>
          <SectionSliderWrapper sx={{ pb: 2.5 }}>
            <SliderWrapper>
              <ClientsSliderControls />
              <Swiper
                onSwiper={setClientSwiper}
                onSlideChange={({ activeIndex }) => setClientSliderActive(activeIndex)}
                slides={corporateClients.map((client, i) => (
                  <Slider key={i} bgImageSrc={client.image}>
                    <ClientSliderTextArea>
                      <Typography
                        variant="h3"
                        fontWeight="800"
                        sx={{ fontFamily: 'Cambon' }}
                        gutterBottom
                      >
                        {t(`companies_page.apartments.clients.${client.type}_title`)}
                      </Typography>
                      <Typography variant="body2" color="primary.80" component="p">
                        {t(`companies_page.apartments.clients.${client.type}_text`)}
                      </Typography>
                    </ClientSliderTextArea>
                  </Slider>
                ))}
                disablePagination={currentBreakpoint !== 'xs'}
                styles={{
                  container: {
                    position: 'unset',
                    height: '100%'
                  }
                }}
                spaceBetween={0}
                loop={false}
              />
            </SliderWrapper>
          </SectionSliderWrapper>
        </Container>
      </Box>
      <Box component="section" sx={{ pt: 2, pb: 4 }} className="map-location">
        <Container maxWidth="xl" disableGutters sx={{ px: 1 }}>
          <Box sx={{ mb: 2, textAlign: ['left', 'center'] }}>
            <Typography
              component="span"
              variant="lead"
              color="primary.light"
              sx={{
                fontWeight: 'bold',
                textTransform: 'uppercase'
              }}
            >
              {t('companies_page.location.section')}
            </Typography>
            <Typography
              component="h2"
              variant="h1"
              sx={{
                fontFamily: 'Cambon',
                my: 1
              }}
            >
              <HtmlTranslation text={t('companies_page.location.title_html')} />
            </Typography>
            <Typography
              component="span"
              variant="h5"
              color="primary.70"
              sx={{ fontWeight: '500' }}
            >
              {t('companies_page.location.description')}
            </Typography>
          </Box>
          {currentBreakpoint === 'xs' ? (
            <SectionSliderWrapper sx={{ pb: 2.5 }}>
              <SliderWrapper>
                <Swiper
                  slides={cities.map((city, i) => (
                    <Slider key={i} bgImageSrc={city.image}>
                      <CityName>
                        <Typography variant="h6" color="primary.90">
                          {city.name}
                        </Typography>
                      </CityName>
                    </Slider>
                  ))}
                  styles={{
                    container: {
                      position: 'unset',
                      height: '100%'
                    }
                  }}
                  spaceBetween={0}
                  loop={false}
                />
              </SliderWrapper>
            </SectionSliderWrapper>
          ) : (
            <CitiesContainer>
              {cities.map((city, i) => (
                <CityCard
                  key={i}
                  component="picture"
                  sx={{ justifyContent: i % 2 ? 'right' : 'left', width: '100%' }}
                >
                  <CityCard component="img" src={city.image} />
                  <CityName>{city.name}</CityName>
                </CityCard>
              ))}
            </CitiesContainer>
          )}
        </Container>
      </Box>
      {['xs', 'sm'].includes(currentBreakpoint) ? (
        <Drawer
          anchor="bottom"
          open={formOpen}
          onClose={() => setFormOpen(false)}
          headerContent={(
            <Typography variant="h4">
              {t('companies_page.hero.cta')}
            </Typography>
          )}
        >
          <Box>
            <CorporateForm
              siteKey={recaptchaSiteKey}
              url={contactFormUrl}
              onCancel={() => setFormOpen(false)}
            />
          </Box>
        </Drawer>
      ) : (
        <Dialog
          open={formOpen}
          onClose={() => setFormOpen(false)}
          maxWidth="xs"
          headerContent={(
            <Typography variant="h4">
              {t('companies_page.hero.cta')}
            </Typography>
          )}
          sx={{ width: 1 }}
        >
          <CorporateForm
            siteKey={recaptchaSiteKey}
            url={contactFormUrl}
            onCancel={() => setFormOpen(false)}
          />
        </Dialog>
      )}
      <CTABottomBar
        actionButtonProps={{
          className: 'request-a-quote-bt-scroll',
          onClick: () => setFormOpen(true),
          children: t('companies_page.cta.button')
        }}
        text={t('companies_page.cta.text')}
        visible={!isHeroButtonVisible}
      />
    </ThemeProvider>
  );
};

export default Corporate;
