import React from 'react';
import { Box } from '../../../elements';
import StepText from './StepText';
import StepImage from './StepImage';

import { useBreakpoint } from '../../../../hooks/useBreakpoint';
import { checkWebp } from '../../../../utils/checkWebp';
import { t } from '../../../../../js/common/translations';

import NumberThree from '../../../../../images/tenant/timeline/number_3.svg';
import StepThreeWebp from '../../../../../images/tenant/timeline/step_3.webp';
import StepThreeJpg from '../../../../../images/tenant/timeline/step_3.jpg';

const StepThree = () => {
  const currentBreakpoint = useBreakpoint();
  const isMobile = ['xs'].includes(currentBreakpoint);
  const isWebp = checkWebp();
  const isFloatPosition = !isMobile && currentBreakpoint !== 'sm';

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: `${isMobile ? 'center' : 'space-between'}`,
      alignItems: 'center',
      left: `${isFloatPosition ? '40px' : '0'}`,
      position: 'relative',
      flexDirection: `${isMobile ? 'column' : 'row'}`
    }}
    >
      <StepText
        title={t('tenant_page.timeline.step_three.title')}
        description={t('tenant_page.timeline.step_three.description_html')}
      />
      {!isMobile && (
        <Box
          component="img"
          src={NumberThree}
          position="relative"
          left={{ sm: '10px', md: '30px' }}
        />
      )}
      <StepImage src={isWebp ? StepThreeWebp : StepThreeJpg} />
    </Box>
  );
};

export default StepThree;
