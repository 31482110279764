import React from 'react';
import { Box } from '../../../elements';
import StepText from './StepText';
import StepImage from './StepImage';

import { t } from '../../../../../js/common/translations';
import { useBreakpoint } from '../../../../hooks/useBreakpoint';
import { checkWebp } from '../../../../utils/checkWebp';

import NumberFour from '../../../../../images/tenant/timeline/number_4.svg';
import StepFourWebp from '../../../../../images/tenant/timeline/step_4.webp';
import StepFourJpg from '../../../../../images/tenant/timeline/step_4.jpg';

const StepFour = () => {
  const currentBreakpoint = useBreakpoint();
  const isMobile = ['xs'].includes(currentBreakpoint);
  const isWebp = checkWebp();
  const isFloatPosition = !isMobile && currentBreakpoint !== 'sm';

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: `${isMobile ? 'center' : 'space-between'}`,
      alignItems: 'center',
      left: `${isFloatPosition ? '80px' : '0'}`,
      position: 'relative',
      flexDirection: `${isMobile ? 'column-reverse' : 'row'}`
    }}
    >
      <StepImage src={isWebp ? StepFourWebp : StepFourJpg} />
      {!isMobile && (
        <Box
          component="img"
          src={NumberFour}
          position="relative"
          right={{ sm: '4px', md: '10px' }}
        />
      )}
      <StepText
        title={t('tenant_page.timeline.step_four.title')}
        description={t('tenant_page.timeline.step_four.description_html')}
      />
    </Box>
  );
};

export default StepFour;
