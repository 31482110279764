import styled, { keyframes } from 'styled-components';
import { Accordion, Box, Button, Grid, Typography, Link, IconButton, Divider } from '../../UI/elements';

export const HeroContainer = styled(Grid).attrs({
  component: 'section',
  className: 'images',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center'
})``;

const hoverOut = keyframes`
  from {
    background: linear-gradient(0deg, #333333 0%, rgba(51, 51, 51, 0.2) 34.75%);
  }
  to {
    background: linear-gradient(0deg, #333333 0%, rgba(51, 51, 51, 0) 34.75%);
  }
`;

const hoverIn = keyframes`
  from {
    background: linear-gradient(0deg, #333333 0%, rgba(51, 51, 51, 0) 34.75%);
  }
  to {
    background: linear-gradient(0deg, #333333 0%, rgba(51, 51, 51, 0.2) 34.75%);
  }
`;

export const HeroImg = styled(Box).attrs({
  height: 1,
  width: 1,
  maxHeight: { xs: 1, md: '60vh' },
  position: 'relative'
})`
  height: calc(100vh - 200px);
  object-fit: cover;

  &::after{
    cursor: pointer;
    content: '';
    background: linear-gradient(0deg, #333333 0%, rgba(51, 51, 51, 0) 34.75%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: ${hoverOut} 0.3s;
  }

  &:hover {
    &::after {
      background: linear-gradient(0deg, #333333 0%, rgba(51, 51, 51, 0.2) 34.75%);
      animation: ${hoverIn} 0.3s;
    }
  }
`;

export const HeroInfoWrapper = styled(Box).attrs({
  position: 'absolute',
  width: 1,
  pl: { xs: 1, lg: 0 },
  pr: { xs: 1, lg: 0 },
  pb: { xs: 2, md: 1 }
})`
  pointer-events: none;
  ${({ theme }) => theme.breakpoints.down('md')} {
    transform: scale(0.95);
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    left: -5vw;
    transform: scale(0.85);
  }
`;

export const ChipText = styled(Typography).attrs({
  fontSize: '0.875rem',
  fontWeight: '600'
})``;

export const HeroButton = styled(Button).attrs({
  color: 'secondary',
  size: 'small'
})`
  pointer-events: auto;
  padding: 0 0.5rem;
`;

export const ApartmentAccordion = styled(Accordion).attrs(({ $mobile }) => ({
  elevation: 0,
  disableGutters: true,
  expandIcon: (!$mobile && []),
  sx: {
    '&:before': {
      display: 'none'
    }
  }
}))`
  .MuiAccordionSummary-root, .MuiCollapse-root {
    background-color: ${({ theme }) => theme.palette.secondary.light};
  }
`;

export const AccordionSummaryTypography = styled(Typography).attrs({
  fontFamily: 'Cambon',
  fontWeight: '800',
  color: 'primary',
  component: 'h3',
  variant: 'h5'
})``;

export const StandardsImg = styled(Box).attrs({
  position: 'relative',
  maxHeight: 270,
  height: 1,
  width: 1
})`
  object-fit: cover;
`;

export const ChooseDatesButton = styled(Button)`
  font-size: ${({ $mobile }) => $mobile ? '0.75rem' : '1rem'};
  display: flex;
  text-align: center;
`;

export const ComingSoonContainer = styled(Box).attrs({
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  justifyContent: 'center',
  alignItems: 'center',
  top: '20vh',
  left: '25vw',
  width: '50vw',
  textAlign: 'center',
  padding: '1rem 2rem',
  gap: '0.5rem',
  borderRadius: '0.5rem',
  backgroundColor: 'rgba(41, 45, 50, 0.5)',
  color: 'secondary.main'
})`
  ${({ theme }) => theme.breakpoints.down('lg')} {
    top: 10vh;
    left: 10vw;
    width: 80vw;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    transform: scale(0.9);
    top: 15vh;
    left: 5vw;
    width: 90vw;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0.5rem 1rem;
    align-items: start;
    text-align: start;
    transform: scale(0.75);
    top: 10vh;
    left: -10vw;
    width: 120vw;
  }
`;

export const ComingSoonTitle = styled(Typography).attrs({
  component: 'h3',
  lineHeight: '2.625rem',
  fontSize: '1.75rem',
  fontWeight: 600
})`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    line-height: 2.25rem;
    font-size: 1.5rem;
  }
`;

export const ComingSoonDescription = styled(Typography).attrs({
  component: 'p',
  lineHeight: '21px',
  fontSize: '0.875rem',
  fontWeight: 400
})`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    line-height: 1.125rem;
    font-size: 0.75rem;
  }
`;

export const ComingSoonBottom = styled(Typography).attrs({
  component: 'p',
  lineHeight: '1.875rem',
  fontSize: '1.25rem',
  fontWeight: 500
})``;

export const ComingSoonWrapper = styled(Box).attrs({
  flexDirection: 'column',
  position: 'absolute',
  textAlign: 'center',
  top: '70vh',
  left: '25vw',
  padding: '1rem 2rem',
  gap: '0.5rem',
  width: '50vw',
  zIndex: 'tooltip',
  borderRadius: '0.25rem',
  backgroundColor: 'rgba(41, 45, 50, 0.5)',
  color: 'secondary.main'
})`
  ${({ theme }) => theme.breakpoints.down('lg')} {
    top: 60vh;
    left: 20vw;
    width: 60vw;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    transform: scale(0.9);
    top: 50vh;
    left: 15vw;
    width: 70vw;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    transform: translate(-50%, -50%) scale(0.75);
    text-align: start;
    gap: 1rem;
    top: 50%;
    left: 50%;
    width: 100vw;
  }
`;

export const BreadcrumbsWrapper = styled(Box).attrs({
  zIndex: 'fab'
})`
  position: absolute;
  top: 96px;
  left: 0;
  margin-left: 3rem;
  display: flex;
  justify-content: flex-start;
  list-style-type: none;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding-left: 0.5rem;
    margin-left: 0;
    top: 72px;  }
`;

export const BreadcrumbsTypography = styled(Typography).attrs({
  color: 'secondary.main'
})`
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 0.65rem;
  }
`;

export const BreadcrumbsLink = styled(Link)`
  text-decoration-color: ${({ theme }) => theme.palette.secondary.main};
  display: flex;
  align-items: center;
  text-decoration: none;

  &:visited {
    text-decoration-color: ${({ theme }) => theme.palette.secondary.main};
 }
`;

export const BreadcrumbsLi = styled(Box).attrs({
  component: 'li'
})`
  display: flex;
`;

export const FixedIconButton = styled(IconButton).attrs({
  sx: {
    zIndex: 'tooltip',
    position: 'fixed',
    right: 0,
    top: 0,
    p: 1
  }
})``;

export const BestDealContainer = styled(Box).attrs({
  display: 'flex',
  gap: '0.5rem',
  padding: '0.5rem',
  borderRadius: '0.25rem',
  border: '1px solid #85E0A3'
})``;

export const BestDealTitle = styled(Typography).attrs({
  color: 'success.dark',
  fontSize: '0.75rem',
  fontWeight: 600
})``;

export const BestDealContent = styled(Typography).attrs({
  fontSize: '0.75rem',
  fontWeight: 500
})``;

export const BestDealPrice = styled(Typography).attrs({
  fontSize: '0.875rem',
  fontWeight: 600,
  lineHeight: '1.125rem',
  margin: 'auto 0 auto auto'
})``;

export const DividerWithText = styled(Divider)`
  & > span {
    padding: 0.25rem 1.5rem;
    font-weight: 500;
    border-radius: 20rem;
    background-color: ${({ theme }) => theme.palette.secondary.light};
  }
`;
