import React from 'react';
import { ThemeProvider } from '../contexts/theme';
import { t } from '../../js/common/translations';
import {
  DividerStyled,
  MainContent,
  Section,
  SectionTitle,
  BlackLink,
  BlueLink,
  SubSection,
  SubSectionTitle,
  SubTitle,
  Title
} from './Sitemap.styles';
import { Box, Container } from '../UI/elements';

const NeighbourhoodLink = ({ neighbourhood }) => (
  <BlueLink href={`${neighbourhood.path}?search[neighbourhoods][]=${neighbourhood.slug}`}>
    {t('sitemap_page.apartments_section.prefix', { neighbourhood: neighbourhood.name })}
  </BlueLink>
);

const Sitemap = ({
  neighbourhoodsSP,
  neighbourhoodsRio,
  neighbourhoodsBrasilia
}) => {
  return (
    <ThemeProvider>
      <Container disableGutters maxWidth="xl" sx={{ p: 1 }}>
        <MainContent>
          <Box textAlign="center">
            <Title>{t('sitemap_page.title')}</Title>
            <Box marginTop={0.5}>
              <SubTitle>{t('sitemap_page.subtitle')}</SubTitle>
            </Box>
          </Box>
          <Section>
            <SectionTitle>{t('sitemap_page.apartments_section.title')}</SectionTitle>
            <DividerStyled />
            <SubSection>
              <Box>
                <SubSectionTitle>
                  <BlackLink href={neighbourhoodsSP[0]?.path}>{'São Paulo'}</BlackLink>
                </SubSectionTitle>
                {neighbourhoodsSP.map((n, index) => (
                  <NeighbourhoodLink key={index} neighbourhood={n} />
                ))}
              </Box>
              <Box>
                <SubSectionTitle>
                  <BlackLink href={neighbourhoodsRio[0]?.path}>{'Rio de Janeiro'}</BlackLink>
                </SubSectionTitle>
                {neighbourhoodsRio.map((n, index) => (
                  <NeighbourhoodLink key={index} neighbourhood={n} />
                ))}
              </Box>
              <Box>
                <SubSectionTitle>
                  <BlackLink href={neighbourhoodsBrasilia[0]?.path}>{'Brasília'}</BlackLink>
                </SubSectionTitle>
                {neighbourhoodsBrasilia.map((n, index) => (
                  <NeighbourhoodLink key={index} neighbourhood={n} />
                ))}
              </Box>
            </SubSection>
          </Section>
          <Section>
            <SectionTitle>
              <BlackLink href={t('sitemap_page.corporate_path')}>{t('sitemap_page.corporate_section.title')}</BlackLink>
            </SectionTitle>
            <DividerStyled />
            <SubSection>
              <Box>
                <SubSectionTitle>{t('sitemap_page.corporate_section.subsection_1.title')}</SubSectionTitle>
              </Box>
            </SubSection>
          </Section>
          <Section>
            <SectionTitle>
              <BlackLink href={t('sitemap_page.owner_path')}>{t('sitemap_page.owner_section.title')}</BlackLink>
            </SectionTitle>
            <DividerStyled />
            <SubSection>
              <Box>
                <SubSectionTitle>{t('sitemap_page.owner_section.subsection_1.title')}</SubSectionTitle>
              </Box>
            </SubSection>
          </Section>
          <Section>
            <SectionTitle>
              <BlackLink href={t('sitemap_page.tenant_path')}>{t('sitemap_page.tenant_section.title')}</BlackLink>
            </SectionTitle>
            <DividerStyled />
            <SubSection>
              <Box>
                <SubSectionTitle>{t('sitemap_page.tenant_section.subsection_1.title')}</SubSectionTitle>
              </Box>
            </SubSection>
          </Section>
          <Section>
            <SectionTitle>
              <BlackLink href={t('sitemap_page.furnished_apartments_path')}>{t('sitemap_page.furnished_apartments_section.title')}</BlackLink>
            </SectionTitle>
            <DividerStyled />
            <SubSection>
              <Box>
                <SubSectionTitle>{t('sitemap_page.furnished_apartments_section.subsection_1.title')}</SubSectionTitle>
              </Box>
            </SubSection>
          </Section>
          <Section>
            <SectionTitle>
              <BlackLink href={t('sitemap_page.about_us_path')}>{t('sitemap_page.about_section.title')}</BlackLink>
            </SectionTitle>
            <DividerStyled />
            <SubSection>
              <Box>
                <SubSectionTitle>{t('sitemap_page.about_section.subsection_1.title')}</SubSectionTitle>
                <BlueLink href={t('sitemap_page.about_us_path')}>
                  {t('sitemap_page.about_section.subsection_1.subtitle_1')}
                </BlueLink>
                <BlueLink href={t('sitemap_page.contact_us_path')}>
                  {t('sitemap_page.about_section.subsection_1.subtitle_2')}
                </BlueLink>
                <BlueLink href={t('sitemap_page.cookies_policy_path')}>
                  {t('sitemap_page.about_section.subsection_1.subtitle_3')}
                </BlueLink>
              </Box>
              <Box>
                <SubSectionTitle>{t('sitemap_page.about_section.subsection_2.title')}</SubSectionTitle>
                <BlueLink href={t('sitemap_page.work_with_us_path')}>
                  {t('sitemap_page.about_section.subsection_2.subtitle_1')}
                </BlueLink>
              </Box>
              <Box>
                <SubSectionTitle>{t('sitemap_page.about_section.subsection_3.title')}</SubSectionTitle>
                <BlueLink href={t('sitemap_page.privacy_policy_path')}>
                  {t('sitemap_page.about_section.subsection_3.subtitle_1')}
                </BlueLink>
                <BlueLink href={t('sitemap_page.faq_path')}>
                  {t('sitemap_page.about_section.subsection_3.subtitle_2')}
                </BlueLink>
                <BlueLink href={t('sitemap_page.terms_and_conditions_path')}>
                  {t('sitemap_page.about_section.subsection_3.subtitle_3')}
                </BlueLink>
              </Box>
            </SubSection>
          </Section>
        </MainContent>
      </Container>
    </ThemeProvider>
  );
};

export default Sitemap;
