import React from 'react';
import { Box } from '../../../elements';
import StepText from './StepText';
import StepImage from './StepImage';

import { t } from '../../../../../js/common/translations';
import { useBreakpoint } from '../../../../hooks/useBreakpoint';
import { checkWebp } from '../../../../utils/checkWebp';

import NumberOne from '../../../../../images/tenant/timeline/number_1.svg';
import StepOneWebp from '../../../../../images/tenant/timeline/step_1.webp';
import StepOneJpg from '../../../../../images/tenant/timeline/step_1.jpg';

const StepOne = () => {
  const currentBreakpoint = useBreakpoint();
  const isMobile = ['xs'].includes(currentBreakpoint);
  const isWebp = checkWebp();

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: `${isMobile ? 'center' : 'space-between'}`,
      alignItems: 'center',
      flexDirection: `${isMobile ? 'column' : 'row'}`
    }}
    >
      <StepText
        title={t('tenant_page.timeline.step_one.title')}
        description={t('tenant_page.timeline.step_one.description_html')}
      />
      {!isMobile && (
        <Box
          component="img"
          src={NumberOne}
          position="relative"
          right={{ sm: '-5px', md: '60px' }}
        />
      )}
      <StepImage src={isWebp ? StepOneWebp : StepOneJpg} />
    </Box>
  );
};

export default StepOne;
