import React from 'react';
import DiamondImage from './DiamondImage';
import { checkWebp } from '../../../../utils/checkWebp';

import HeroImageOneWebp from '../../../../../images/tenant/hero_1.webp';
import HeroImageOneJpg from '../../../../../images/tenant/hero_1.jpg';

const DiamondHeroImageOne = () => {
  const isWebp = checkWebp();

  return (
    <DiamondImage
      width={{ xs: 200, sm: 480, lg: 500, xl: 580 }}
      height={{ xs: 200, sm: 480, lg: 500, xl: 580 }}
      sx={{
        width: { xs: 260, sm: 660, lg: 700, xl: 780 },
        height: { xs: 260, sm: 660, lg: 700, xl: 780 },
        objectPosition: { xs: '-40px -30px', sm: '-160px' }
      }}
      src={isWebp ? HeroImageOneWebp : HeroImageOneJpg}
    />
  );
};

export default DiamondHeroImageOne;
